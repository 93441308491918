<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section">

    <v-dialog  v-model="alert_msg"  width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mensagem
        </v-card-title>

        <v-card-text>
            {{alert_msg_descricao}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            color="primary"
            text
            @click="alert_msg = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>



 <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="relatorio"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="740px"
        :html-to-pdf-options="htmlToPdfOptions"

        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf">
        <section slot="pdf-content">
        
            <v-container fluid>
            </br>
             <v-container bg fill-height grid-list-md text-xs-center>
                <v-layout row wrap align-center>
                    <v-flex>
                        <h2><p class="text-md-center"> {{'Relatório Analítico de Movimentação do Ticket'}}</p></h2>
                    </v-flex>
                </v-layout>
            </v-container>
            
                 <v-col class="d-flex" cols="12" sm="12">
                    <v-col class="d-flex">
                    <span> Filtro: </span> <br/>
 
                   <span v-if="this.tipo_consulta == '1'"> Código do Cartão:   {{ n_consulta }} </span><br/>
                   <span v-if="this.tipo_consulta == '1'"> Período - Início (Uso):   {{ filtro.dataI }} </span><br/>
                   <span v-if="this.tipo_consulta == '1'"> Período - Fim (Uso):   {{ filtro.dataF }} </span>

                   <span v-if="this.tipo_consulta == '2'"> Nº do Ticket:   {{ n_consulta_ticket }} </span>

                    </v-col>

                  </v-col>              
            <v-data-table  group-by="cartao" group-desc  :headers="fields" hide-default-footer :items="card_filtrado.lista" item-key="codigo" id="tb" class="elevation-0" :loading="loading">
            
                  <template v-slot:group.header="{ group, headers, toggle, isOpen, items}">

                    <td :colspan="headers.length">
                     
                      <span class="mx-5 font-weight-bold">Nº do Ticket: {{ group }}  </span> <br/>
                      <span class="mx-5 font-weight-bold">Inscrição / Contrato: {{ items[0].inscricao }}  </span><br/>
                      <span class="mx-5 font-weight-bold">Total de Compras no Período: R$ {{ formatPrice(items[0].valor_acumulado) }}   </span><br/>

                    </td>
                  </template>
                    <template v-slot:item.data="{ item }">
                        <span>{{ item.data }}</span>
                    </template>

                    <template v-slot:item.valor_compra="{ item }">
                        <span>R$ {{ formatPrice(item.valor_compra) }}</span>
                    </template>

            </v-data-table>

             </br>

                <h3><p class="text-md-right">
                    Valor Usado - R$ {{formatPrice(this.card_filtrado.valor_usado)}} </br>
                </p>
                </h3>


            </v-container> 
        </section>
    </vue-html2pdf>


<div class="card">
  <div class="card-body">
    <h4 class="card-title"></h4>
    <base-material-card
      icon="mdi-ticket"
      title="Relatório Analítico de Movimentação do Ticket"
      class="px-5 py-3"
    >

      <v-row  class="px-3 py-3" >
          <v-col class="d-flex" cols="12" sm="12">
            <v-radio-group
                v-model="tipo_consulta"
                row >
                <v-radio
                  label="Código do Cartão e Período de Uso"
                  value="1"
                ></v-radio>
                <v-radio
                  label="Ticket"
                  value="2"
                ></v-radio>
            </v-radio-group>
          </v-col>
        
            <v-col class="d-flex" style="height:60px" cols="3" sm="3">
                <v-select v-model="n_consulta"  :rules="[v => !!v || 'Campo Obrigatório']"  :items="codigos" dense label="Código do Cartão*" outlined v-if="this.tipo_consulta == '1'"></v-select>
                <v-text-field v-model="n_consulta_ticket" label="Nº do Ticket"  :rules="[v => !!v || 'Campo Obrigatório']"  dense outlined v-if="this.tipo_consulta == '2'" ></v-text-field>
            </v-col>

             
          
            <v-col class="d-flex" cols="9" sm="9" v-if="this.tipo_consulta == '1'">
                     <v-row  class="px-2" >
                              <v-col class="d-flex" cols="6" sm="6">

                                  <v-dialog
                                      ref="dialogi"
                                      v-model="date_modal_p_i"
                                      :return-value.sync="filtro.dataI"
                                      persistent
                                      width="290px"
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field outlined dense
                                          v-model="filtro.dataI"
                                          label="Período - Início (Uso)"
                                          prepend-icon="mdi-calendar"
                                          readonly
                                          v-bind="attrs"
                                          v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="filtro.dataI"
                                        scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          text
                                          color="primary"
                                          @click="date_modal_p_i = false">
                                          Fechar
                                        </v-btn>
                                        <v-btn
                                          text
                                          color="primary"
                                          @click="$refs.dialogi.save(currentDate(filtro.dataI))">
                                          OK
                                        </v-btn>
                                      </v-date-picker>
                                    </v-dialog>

                              </v-col>
                              
                              <v-col class="d-flex" cols="6" sm="6">

                                <v-dialog
                                    ref="dialog"
                                    v-model="date_modal_p_f"
                                    :return-value.sync="filtro.dataF"
                                    persistent
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field outlined dense
                                        v-model="filtro.dataF"
                                        label="Período - Fim (Uso)"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="filtro.dataF"
                                      scrollable
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="date_modal_p_f = false"
                                      >
                                        Fechar
                                      </v-btn>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dialog.save(currentDate(filtro.dataF))"
                                      >
                                        OK
                                      </v-btn>
                                    </v-date-picker>
                                  </v-dialog>

                              </v-col>
                      </v-row>  
                       <v-col class="d-flex" cols="6" sm="6">
                                <v-btn  color="success text-white" style="margin-top:5px;" small  @click="filtrar()">Filtrar
                                    <v-icon
                                    right
                                    dark>
                                    mdi-filter
                                    </v-icon>
                                </v-btn>
                       </v-col> 
            </v-col>  

            <v-col class="d-flex" cols="3" sm="3" v-if="this.tipo_consulta == '2'">
                                <v-btn  color="success text-white" style="margin-top:5px;" small  @click="filtrar()">Filtrar
                                    <v-icon
                                    right
                                    dark>
                                    mdi-filter
                                    </v-icon>
                                </v-btn>
             </v-col> 
      </v-row>

        <v-data-table  group-by="cartao" group-desc show-group-by :headers="fields" :items="card_filtrado.lista" item-key="codigo" id="tb" class="elevation-0" :loading="loading">
            
            <template v-slot:group.header="{ group, headers, toggle, isOpen, items}">
              
              

              <td :colspan="headers.length">
                <v-btn @click="toggle" x-small icon :ref="group">
                    <v-icon v-if="isOpen">mdi-plus</v-icon>
                    <v-icon v-else>mdi-minus</v-icon>
                </v-btn>
              

                <span class="mx-5 font-weight-bold">Nº do Ticket: {{ group }}  </span>
                                <span class="mx-5 font-weight-bold">Inscricao/Contrato: {{ items[0].inscricao }}  </span>
                 <span class="mx-5 font-weight-bold">Total de Compras no Período: R$ {{ formatPrice(items[0].valor_acumulado) }}   </span>

              </td>
            </template>

         

            <template v-slot:item.data="{ item }">
              <span>{{ item.data }}</span>
            </template>

            <template v-slot:item.valor_compra="{ item }">
              <span>R$ {{ formatPrice(item.valor_compra) }}</span>
            </template>

        </v-data-table>
        </br>

        <h3><p class="text-md-right">
                Total Usado - R$ {{formatPrice(this.card_filtrado.valor_usado)}} </br>
                </p></h3>

    </base-material-card>
      <br/>
     <v-row
      > 
        <v-btn  color="purple text-white" :loading="showload"  @click="generateReport()"> Imprimir
          <v-icon
            right
            dark
          >
            mdi-printer
          </v-icon>

        </v-btn>

    </v-row>

  </div>
</div>
   
  </v-container>
</template>
<script>
  import Cartao from '../../../services/cartao'
  import moment from 'moment'
  import VueBarcode from 'vue-barcode';
  import VueHtml2pdf from 'vue-html2pdf'

    var pdfMake = require('pdfmake/build/pdfmake.js');
    var pdfFonts = require('pdfmake/build/vfs_fonts.js');
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

  export default {

     created() {
          if(this.$store.state.auth.status.loggedIn == false){
            this.$router.push('/')
          }
      },

    components: {
        'barcode': VueBarcode
    },

    data: () => ({
      periodo_selecionado: 'Disponível',
      show: true,
      showload:false,
      tipo_consulta: "1",
      //pdf: new jsPDF(),
      n_consulta: '',
      n_consulta_ticket: '',
      btn_status: false,
      alert_msg:false,
      alert_msg_descricao: '',
      showload: false,
      date_modal_p_i: false,
      date_modal_p_f: false,
      date_modal_i: false,
      date_modal_f: false,
      loading: false,
      capelas: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
      ],

      codigos: [
        
      ],

      periodos: [
        'Disponível',
        'Usado'
      ],

      "Liberar Carência Dependente": {
        field: "sem_carencia_dependene",
        callback: (value) => {
        switch (value) {
          case true: return 'Sim'
          case false: return 'Não'
          default: ''
        }
        },
      },

       "Data": {
        field: "data",
        callback: (value) => {
          return  moment(value).format('DD/MM/YYYY')
        },
      },


    select_status: true,
       fields:[
                 { text: 'Data Uso', sorter: false, filter: false, value:'data', class:'title'},
                    { text: 'Código do Cartão', sorter: false, filter: false, value:'codigocartao', class:'title'},     
                { text: 'Inscricao', sorter: false, filter: false, value:'inscricao', class:'title'},
        { text: 'Valor da Compra', sorter: false, filter: false, value:'valor_compra', class:'title'},

      ],
      htmlToPdfOptions: {
            //top: 200,
            //left:200,
            margin:10,
           //margin: 30,
            image: {
                type: 'jpeg', 
                quality: 0.98
            },
      },
      card_filtrado:{
            valor: 0,
            valor_usado: 0,
            valor_inicial: 0,
            lista: [],

      },

      filtro:{
          dataI: '',
          dataF: '',
      },
      
      rules: [
        value => !!value || 'Required.',
      ],
      dialogAdd: false,
      msg_dialog: '',

    }),

    mounted () {
      this.listar()
    },

    methods: {

      createPDF() {
        var docDefinition = {
        content: [
            {
                table: {
                    headerRows: 1,
                    widths: [ '*', 'auto', 100, '*' ],

                    body: []
                }
            }
        ]
        };
        docDefinition.content[0].table.body.push(this.columns);
        for(var i=0;i<this.checkedRows.length;i++){
            docDefinition.content[0].table.body.push(Object.values(this.checkedRows[i]));  
        }
        pdfMake.createPdf(docDefinition).download('PO.pdf');
      },


      onSubmit(event) {
         alert('subm')
      },

      onReset(event) {
        this.lote.qtd = 1
        this.lote.desconto_adesao = 0
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      },

        generateReport () {
            
            this.showload = true
            this.$refs.html2Pdf.generatePdf();
            this.showload = false
        },

     

      filtrar(){


          if(this.tipo_consulta == "1"){

              if(this.filtro.dataI != '' && this.filtro.dataF != ''){

                  if(this.n_consulta != '' ){
                    this.loading = true

                    Cartao.relatorio_cartao(this.n_consulta, this.filtro).then(response => {
                          this.card_filtrado.lista = response.data.lista;
                          this.card_filtrado.valor_usado = response.data.valor_usado;

                      }).catch(e => {
                          this.alert_msg = true;
                              this.alert_msg_descricao = e.response.data
                          if(e.response.status === 403){
                              this.alert_msg_descricao = "Usuário não tem permissão"
                          }
                      }).finally(() => {
                          this.loading = false
                      })

                  }else{
                      this.alert_msg = true;
                      this.alert_msg_descricao = 'Campo Inscrição ou Cartão Obrigatório'

                  }
                }else{
                    this.alert_msg = true;
                    this.alert_msg_descricao = 'Campo Data Inicial e Final Obrigatório'

                }
          }else

          if(this.tipo_consulta == "2"){


              if(this.n_consulta_ticket != '' ){
                    this.loading = true

                    Cartao.relatorio_cartao_ticket(this.n_consulta_ticket).then(response => {
                          this.card_filtrado.lista = response.data.lista;
                          this.card_filtrado.valor = response.data.valor;
                          this.card_filtrado.valor_usado = response.data.valor_usado;

                      }).catch(e => {
                          this.alert_msg = true;
                              this.alert_msg_descricao = e.response.data
                          if(e.response.status === 403){
                              this.alert_msg_descricao = "Usuário não tem permissão"
                          }
                      }).finally(() => {
                          this.loading = false
                      })

                  }else{
                      this.alert_msg = true;
                      this.alert_msg_descricao = 'Campo Ticket Obrigatório'

                  }

          }



      },


      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
      },

     
     listar () {  
      Cartao.listacodigos("todos").then(response => {
            this.codigos = response.data
        }).catch(e => {
          
        }).finally(() => {
        })
      },

    currentDateTime(date) {
      return moment(date).format('DD/MM/YYYY HH:mm:ss')
    },




      currentDate(date) {
      return moment(date).format('DD/MM/YYYY')
    },


    parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },

    getBadge (status) {
      switch (status) {
         case true: return 'green lighten-3'
        case false: return 'red lighten-2'
        default: 'success'
      }
      },

      getStatus(status) {
      switch (status) {
        case true: return 'Ativo'
        case false: return 'Inativo'
        default: ''
      }
    },

    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
      getActionText(status) {
      switch (status) {
        case true: return 'Desabilitar'
        case false: return 'Habilitar'
        default: ''
      }
    },

    getActionColor(status) {
      switch (status) {
        case true: return 'red lighten-2'
        case false: return 'orange'
        default: ''
      }
    },

    

    },
    components: {
        VueHtml2pdf
    }
  }

</script>
